import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuVisible: false,
  logoutModalVisible: false,
  status: 'idle'
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    showMenu: (state) => {
      state.menuVisible = true;
    },
    hideMenu: (state) => {
      state.menuVisible = false;
    },
    showLogoutModal: (state) => {
      state.logoutModalVisible = true;
    },
    hideLogoutModal: (state) => {
      state.logoutModalVisible = false;
    }
  }
});

//actions
export const { showMenu, hideMenu, showLogoutModal, hideLogoutModal } = settingSlice.actions;

//selectors
export const menuVisible = (state) => state.setting.menuVisible;
export const logoutModalVisible = (state) => state.setting.logoutModalVisible;

export default settingSlice.reducer;
