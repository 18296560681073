import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  fetchCourseFull,
  fetchCourses,
  getCurrentLessonStep,
  setLesson
} from 'src/store/training/trainingSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import TrainingLayout from 'src/layout/Training';
import Content from 'src/components/Training/Course/Lesson/Content';
import useQuery from 'src/hooks/useQuery';

const TrainingLesson = () => {
  const params = useParams();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const courses = useAppSelector((state) => state.training.courses);
  const course = useAppSelector((state) => state.training.currentCourse);
  const lesson = useAppSelector((state) => state.training.currentLesson);
  const currentLessonStep = useAppSelector(getCurrentLessonStep(query.get('lessonStepId')));
  const error = useAppSelector((state) => state.training.error);

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    if (courses.length) {
      dispatch(fetchCourseFull(params.courseCode));
    }
  }, [courses]);

  useEffect(() => {
    if (course) {
      dispatch(setLesson({ lessonId: params.lessonId }));
    }
  }, [course]);

  if (error) {
    return <Redirect to={'/learning-dashboard'} />;
  }

  if (course && lesson && currentLessonStep) {
    return (
      <TrainingLayout
        title={course.name}
        breadcrumb={[
          { title: 'Learning courses', path: '/learning-dashboard' },
          {
            title: course.name,
            path: `/learning/course/${course.courseCode}/dashboard`
          },
          { title: `Lesson ${lesson.lessonNumber}: ${currentLessonStep.title}`, path: '#' }
        ]}>
        <Content course={course} lesson={lesson} lessonStep={currentLessonStep} />
      </TrainingLayout>
    );
  } else {
    return null;
  }
};

export default TrainingLesson;
