import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBlueIcon from 'src/assets/images/training/arrow-green-icon.svg';
import LockIcon from 'src/assets/images/training/lock-icon.svg';
import QuizIcon from 'src/assets/images/training/quiz-icon.svg';
import { useAppSelector } from 'src/hooks';
import ProgressBar from '../Common/ProgressBar';

const LessonItem = ({ id, title, name, type = 'locked', total, done, quiz }) => {
  const course = useAppSelector((state) => state.training.currentCourse);
  const icon = {
    ongoing: ArrowBlueIcon,
    locked: LockIcon,
    quiz: QuizIcon
  };

  return (
    <div>
      <div className={`training_course_dashboard_lesson type_${type}`}>
        <Link
          to={
            quiz
              ? `/learning/course/${course.courseCode}/quiz-start`
              : `/learning/course/${course.courseCode}/lesson/${id}`
          }
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}>
          <div className="training_course_dashboard_lesson_body">
            <div className="training_course_dashboard_lesson_body_left">
              <div className="training_course_dashboard_lesson_title">{title}</div>
              <span className="training_course_dashboard_lesson_name">{name}</span>
            </div>
            <img src={icon[type]} alt="Icon" className="training_course_dashboard_lesson_icon" />
          </div>
          <div className="training_course_dashboard_lesson_progress">
            <ProgressBar total={total} done={done} />
          </div>
        </Link>
      </div>
      <div className={`training_course_dashboard_lesson_divider`}></div>
    </div>
  );
};

export default LessonItem;
