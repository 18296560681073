import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CircleRedIcon from 'src/assets/images/training/close-circle-black.svg';
import ExitIcon from 'src/assets/images/training/lesson1-exit-icon.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';

const ExitLessonModal = ({ visible, onCloseModal }) => {
  useEffect(() => {
    if (visible) {
      document.getElementById('root').classList.add('position-fixed');
    } else {
      document.getElementById('root').classList.remove('position-fixed');
    }
  }, [visible]);

  return (
    <div className="training_course_lesson_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="training_course_lesson_modal_backdrop" onClick={() => onCloseModal()} />
      <div className="training_course_lesson_modal_body">
        <div className="training_course_lesson_modal_content">
          <button className="training_course_lesson_modal_close_btn" onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img src={ExitIcon} alt="Icon" className="training_course_lesson_modal_icon" />
          <div className="training_course_lesson_modal_title">
            Are you sure you want to exit the lesson?
          </div>
          <div className="training_course_lesson_modal_description">
            You can continue training at any time.
          </div>
          <div className="training_course_lesson_modal_actions">
            <Link
              to={`/`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton
                text="Back to course overview"
                noIcon
                className="training_course_lesson_modal_back"
              />
            </Link>
            <SecondaryButton
              text="Return to lesson"
              className="training_course_lesson_modal_next"
              onClick={() => onCloseModal()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitLessonModal;
