import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import DocumentIcon from 'src/assets/images/training/document-icon.svg';
import ItemGroups from './ItemGroups';
import {
  getNextLessonStep,
  getPrevLessonStep,
  addCourseActivity
} from 'src/store/training/trainingSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { markDownFormat } from 'src/utils/formatstring';

const Content = ({ course, lesson, lessonStep }) => {
  const dispatch = useAppDispatch();
  const nextlesson = useAppSelector(getNextLessonStep(lessonStep.lessonStepId));
  const prevlesson = useAppSelector(getPrevLessonStep(lessonStep.lessonStepId));
  const location = useLocation();
  const history = useHistory();
  const [requireCompleteItems, setRequiredCompleteItems] = useState([]);

  useEffect(() => {
    if (lessonStep) {
      let result = [];
      for (let index = 0; index < lessonStep.lessonStepItemGroups.length; index++) {
        let group = lessonStep.lessonStepItemGroups[index];
        switch (group.groupPresentationType) {
          case 'Checklist Progressive': {
            result.push({
              groupId: group.lessonStepItemGroupId,
              type: 'Checklist Progressive',
              completed: false
            });
            break;
          }
          case 'Checklist Immediate': {
            result.push({
              groupId: group.lessonStepItemGroupId,
              type: 'Checklist Immediate',
              completed: false
            });
            break;
          }
        }
      }
      setRequiredCompleteItems(result);
    }
  }, [lessonStep]);

  const handleGoNext = () => {
    const data = {
      courseKey: course.courseId,
      retries: 0,
      lessonKey: lesson.lessonId,
      lessonStepKey: lessonStep.lessonStepId
    };
    // lessonCheckInKey: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    // quizKey: 'e355c89a-f022-4474-acab-c457aa3c92bf'
    dispatch(addCourseActivity(data))
      .then(() => {
        let params = new URLSearchParams();
        params.append('lessonStepId', nextlesson.lessonStepId);
        history.push(`${location.pathname}?${params.toString()}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGoPrev = () => {
    let params = new URLSearchParams();
    params.append('lessonStepId', prevlesson.lessonStepId);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const handleGoCheckIn = () => {
    const data = {
      courseKey: course.courseId,
      retries: 0,
      lessonKey: lesson.lessonId,
      lessonStepKey: lessonStep.lessonStepId
    };

    dispatch(addCourseActivity(data))
      .then(() => {
        history.push(location.pathname + '/check-in');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGoNextLesson = () => {
    // console.log(course);
    const data = {
      courseKey: course.courseId,
      retries: 0,
      lessonKey: lesson.lessonId,
      lessonStepKey: lessonStep.lessonStepId
    };

    dispatch(addCourseActivity(data))
      .then(() => {
        const findData = course.lessons.find(
          (item) => item.lessonNumber == lesson.lessonNumber + 1
        );
        if (findData)
          history.push(`/learning/course/${course.courseCode}/lesson/${findData.lessonId}`);
        else {
          history.push(`/learning/course/${course.courseCode}/quiz-start`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isCompleteLesson = () => {
    return !requireCompleteItems.find((e) => !e.completed);
  };

  const handleCompleteItem = (groupId, completed = true) => {
    setRequiredCompleteItems(
      requireCompleteItems.map((e) => (e.groupId == groupId ? { ...e, completed: completed } : e))
    );
  };

  return (
    <>
      <div className="training_course_lesson">
        <div className="training_course_lesson_content">
          <div className="training_course_lesson_title">
            <div dangerouslySetInnerHTML={{ __html: markDownFormat(lessonStep.title) }} />
          </div>
          {lessonStep.lessonStepItemGroups.map((itemGroup, key) => (
            <ItemGroups group={itemGroup} key={key} onCompleteToggle={handleCompleteItem} />
          ))}
          <div className="training_course_lesson_action">
            {prevlesson && (
              <PrimaryButton
                className="back_btn"
                text={'Back'}
                onClick={() => handleGoPrev()}
                noIcon
              />
            )}
            {nextlesson ? (
              <SecondaryButton
                className="next_btn"
                text={'Next'}
                onClick={() => handleGoNext()}
                disabled={!isCompleteLesson()}
              />
            ) : lesson.lessonCheckInItems.length > 0 ? (
              <SecondaryButton
                className="next_btn"
                text={'Lesson check-in'}
                onClick={() => handleGoCheckIn()}
                disabled={!isCompleteLesson()}
              />
            ) : (
              <SecondaryButton
                className="next_btn"
                text={'Proceed'}
                onClick={() => handleGoNextLesson()}
                disabled={!isCompleteLesson()}
              />
            )}
          </div>
        </div>
      </div>
      <div className="training_course_lesson_right">
        <div className="training_course_lesson_right_content">
          <div className="training_course_lesson_right_title">{`Lesson ${lesson.lessonNumber} progress`}</div>
          <div className="training_course_lesson_right_progress">
            {lesson.lessonSteps.map((e, key) => (
              <div
                className={`training_course_lesson_right_progress_item${
                  e.lessonStepId == lessonStep.lessonStepId ? ' active' : ''
                }${e.stepNumber < lessonStep.stepNumber ? ' complete' : ''}`}
                key={key}>
                {e.title}
              </div>
            ))}
            {lesson.lessonCheckInItems.length > 0 && (
              <div className="training_course_lesson_right_progress_item with_icon">
                <img src={DocumentIcon} alt="" className="" />
                <span>Lesson check-in</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
