import React from 'react';
import { Link } from 'react-router-dom';
import CompleteLessonImg from 'src/assets/images/training/complete-lesson.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';

const QuizPassedModal = ({ visible, mark, pointsEarned, courseCode }) => {
  return (
    <div className="training_quiz_result_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="training_quiz_result_modal_backdrop" />
      <div className="training_quiz_result_modal_body">
        <div className="training_quiz_result_modal_content">
          <img src={CompleteLessonImg} alt="Icon" className="training_quiz_result_modal_icon" />
          <div className="training_quiz_result_modal_title">You’ve passed!</div>
          <div className="training_quiz_result_modal_description">
            You have passed Airtime and Data Sales!
            <br /> Your quiz score was {parseFloat(mark).toFixed(2)}% and you’ve earned{' '}
            {pointsEarned} points.
          </div>
          <div className="training_quiz_result_modal_actions">
            <Link
              to={`/learning-dashboard`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton text="All learning courses" />
            </Link>
            <Link
              to={`/learning/course/${courseCode}/quiz-result`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <SecondaryButton text="Review answers" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizPassedModal;
