import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import GreenArrow from '../../assets/images/dashboard/arrow-green.svg';
import FooterLogo from 'src/assets/images/go-auth-footer-logo.png';
import SelfieImg from '../../assets/images/selfie.png';
// import CourseIcon1 from '../../assets/images/dashboard/course-icon-1.svg';
// import CourseIcon2 from '../../assets/images/dashboard/course-icon-2.svg';
// import CourseIcon3 from '../../assets/images/dashboard/course-icon-3.svg';
// import CourseIcon4 from '../../assets/images/dashboard/course-icon-4.svg';
// import CourseIcon5 from '../../assets/images/dashboard/course-icon-5.svg';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
  fetchCourses,
  fetchUserDashboardData,
  fullCourses,
  isDashboardLoading
} from 'src/store/training/trainingSlice';
import { baseURL } from 'src/utils/api';
import { Link, Redirect } from 'react-router-dom';
import { showLogoutModal } from 'src/store/setting/settingSlice';

const Dashboard = () => {
  const courses = useAppSelector(fullCourses);
  const userId = useAppSelector((state) => state.auth.userId);
  const token = useAppSelector((state) => state.auth.token);
  const dashboardLoading = useAppSelector(isDashboardLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
    if (userId && token) {
      dispatch(fetchUserDashboardData({ userId, token }));
    }
  }, [userId, token]);

  if (!userId || !token) {
    return <Redirect to={'/auth/login'} />;
  }

  const buttonTexts = (status) => {
    switch (status) {
      case 'Quiz in progress': {
        return 'Continue';
      }
      case 'Course content in progress': {
        return 'Continue';
      }
      case 'Completed and passed': {
        return 'Completed';
      }
      default: {
        return 'Start';
      }
    }
  };

  const buttonClass = (status) => {
    switch (status) {
      case 'Quiz in progress': {
        return 'progress';
      }
      case 'Course content in progress': {
        return 'progress';
      }
      case 'Completed and passed': {
        return 'completed';
      }
      default: {
        return 'todo';
      }
    }
  };

  if (dashboardLoading) {
    return <div className="">skeleton</div>;
  } else {
    return (
      <div className="dashboard">
        <div className="dashboard_banner">
          <div className="dashboard_banner_title">Learning Dashboard</div>
          <img className="dashboard_banner_img" alt="Arrow" src={GreenArrow} />
        </div>
        <div className="dashboard_body">
          <div className="dashboard_left">
            <div className="dashboard_profile_box">
              <div className="dashboard_profile_box_info">
                <div
                  className="dashboard_profile_box_avatar"
                  onClick={() => dispatch(showLogoutModal())}>
                  <img src={SelfieImg} alt="Avatar" className="dashboard_profile_box_avatar_img" />
                  {/* <div className="dashboard_profile_box_avatar_badge">0</div> */}
                </div>
                <div className="">
                  <div className="dashboard_profile_box_title">Kabelo Nkosi</div>
                  <div className="dashboard_profile_box_description">Total Points: 200</div>
                </div>
              </div>
              <div className="dashboard_profile_box_points">
                <div className="dashboard_profile_box_point">
                  <div className="dashboard_profile_box_point_score">200</div>
                  <div className="dashboard_profile_box_point_description">Points earned</div>
                </div>
                <div className="dashboard_profile_box_divider"></div>
                <div className="dashboard_profile_box_point">
                  <div className="dashboard_profile_box_point_score">2/6</div>
                  <div className="dashboard_profile_box_point_description">Courses Completed</div>
                </div>
              </div>
            </div>
            <div className="dashboard_current_course_box">
              <div className="dashboard_current_course_title">
                Current Course:{' '}
                <span className="dashboard_current_course_name">Airtime & Date Sales</span>
              </div>
              <div className="dashboard_current_course_body">
                <div className="dashboard_current_course_percent_graph">
                  <div className="dashboard_current_course_percent_graph_container">
                    <CircularProgress
                      className="dashboard_current_course_percent_graph_circular_pg"
                      thickness={4}
                      variant="determinate"
                      value={100}
                      size={137}
                    />
                    <div className="dashboard_current_course_percent_graph_text_pg_container">
                      <div className="dashboard_current_course_percent_graph_statistic_percent_text">
                        0
                      </div>
                      <div className="dashboard_current_course_percent_graph_statistic_percent_symbol">
                        %
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard_current_course_description">
                  <div>100 Points</div>
                  <div>4/6 Lessons done</div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard_right">
            <div className="dashboard_points_title to_earn">Complete to earn points</div>

            <div className="dashboard_points_lists">
              {courses
                .filter((e) => e.status != 'Completed and passed')
                .map((course, index) => (
                  <div className="dashboard_points_list" key={index}>
                    <div
                      className="dashboard_points_list_icon"
                      style={{ backgroundColor: course.defaultBackgroundColour }}>
                      <img src={`${baseURL}image/${course.defaultIcon}`} alt="Icon" />
                    </div>
                    <div className="dashboard_points_list_title">{course.name}</div>
                    <div className="dashboard_points_list_progresses">
                      <div className={`dashboard_points_list_progress_item active`}></div>
                      <div className={`dashboard_points_list_progress_item active`}></div>
                      <div className={`dashboard_points_list_progress_item`}></div>
                      <div className={`dashboard_points_list_progress_item`}></div>
                    </div>
                    <div className="dashboard_points_list_point">{course.points} Points</div>
                    <Link
                      to={`/learning/course/${course.courseCode}/dashboard`}
                      className={`dashboard_points_list_btn ${buttonClass(course.status)}`}
                      onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                      }}>
                      {buttonTexts(course.status)}
                    </Link>
                  </div>
                ))}
            </div>
            {courses.find((item) => item.status == 'Completed and passed') && (
              <>
                <div className="dashboard_points_divider"></div>
                <div className="dashboard_points_title">Completed Courses</div>
              </>
            )}
            <div className="dashboard_points_lists">
              {courses
                .filter((e) => e.status == 'Completed and passed')
                .map((course, index) => (
                  <div className="dashboard_points_list" key={index}>
                    <div
                      className="dashboard_points_list_icon"
                      style={{ backgroundColor: course.defaultBackgroundColour }}>
                      <img src={`${baseURL}image/${course.defaultIcon}`} alt="Icon" />
                    </div>
                    <div className="dashboard_points_list_title">{course.name}</div>
                    <div className="dashboard_points_list_point">{course.points} Points</div>
                    <Link
                      to={`/learning/course/${course.courseCode}/dashboard`}
                      className={`dashboard_points_list_btn ${buttonClass(course.status)}`}
                      onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                      }}>
                      {buttonTexts(course.status)}
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="dashboard_footer">
          <img src={FooterLogo} alt="Logo" className="dashboard_footer_logo" />
          <div className="dashboard_footer_text">© 2023 Learning Management powered by Flash.</div>
        </div>
      </div>
    );
  }
};

export default Dashboard;
