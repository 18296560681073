import React from 'react';
import FlashLogo from 'src/assets/images/login pages/flash_logo.png';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'src/utils/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from 'src/store/auth/authSlice';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const FormSchema = Yup.object().shape({
  email: Yup.string().email('Valid Email is required').required('Email is required'),
  password: Yup.string()
});

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [allowMicrosoftAadLogin, setAllowMicrosoftAadLogin] = useState(null);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: FormSchema,
    onSubmit: (data) => {
      try {
        if (allowMicrosoftAadLogin == 0) {
          if (data.password) {
            axios
              .post(`${process.env.REACT_APP_SERVICES_URL}/index.php/v2/user/login`, {
                user_email: data.email,
                user_password: data.password
              })
              .then((response) => {
                dispatch(
                  login({
                    token: response.data.data['Authorization_Token'],
                    userId: response.data.data['id']
                  })
                );
                history.push('/');
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          axios
            .post(`${process.env.REACT_APP_SERVICES_URL}/index.php/v2/user/login`, {
              user_email: data.email,
              user_password: Math.floor(Math.random() * 10000).toString(),
              apple_login: '1'
            })
            .then((response) => {
              axios.defaults.headers.common['Content-Type'] = 'application/json';
              axios({
                url: `${process.env.REACT_APP_SERVICES_URL}/index.php/v2/users?id=${response.data.data.id}`,
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: {}
              })
                .then((response) => {
                  setAllowMicrosoftAadLogin(response.data[0]['allow_microsoft_aad_login']);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        alert('something went wrong');
        console.log(error);
      }
    }
  });

  useEffect(() => {
    if (allowMicrosoftAadLogin == 1) {
      location.href = `${process.env.REACT_APP_SERVICES_URL}/index.php/v2/azure/login?url={https://life.flash.co.za/app,backend}`;
    }
  }, [allowMicrosoftAadLogin]);

  return (
    <div className="login_page">
      <form className="login_page_form" onSubmit={formik.handleSubmit}>
        <img src={FlashLogo} alt="Logo" className="login_page_logo" />
        <input
          type="text"
          placeholder="E-mail"
          className="login_page_input"
          {...formik.getFieldProps('email')}
        />
        <input
          type="password"
          placeholder=""
          className="login_page_input"
          {...formik.getFieldProps('password')}
          hidden={allowMicrosoftAadLogin != 0}
        />
        <button className="login_page_btn" type="submit" disabled={!formik.isValid}>
          <span>Sign In</span>
          <KeyboardDoubleArrowRightIcon />
        </button>
      </form>
    </div>
  );
};

export default Login;
