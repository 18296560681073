// import Home from '../pages/Home';
import TrainingCourseDashboard from '../pages/Training/Course/Dashboard';
import TrainingCourseTermsToKnow from '../pages/Training/Course/TermsToKnow';
import TrainingCourseLesson from '../pages/Training/Course/Lesson';
import TrainingCourseLessonCheckIn from 'src/pages/Training/Course/LessonCheckin';
import TrainingQuizStart from '../pages/Training/Course/QuizStart';
import TrainingQuizQuestion from '../pages/Training/Course/QuizQuestion';
import TrainingQuizReview from 'src/pages/Training/Course/QuizResult';
import Dashboard from '../pages/Dashboard';
// import AccountDeleted from 'src/pages/Auth/AccountDeleted';
// import AccountDeletedCallBack from 'src/pages/Auth/AccountDeleted/Callback';
// import AccountDeletedDoNotCallBack from 'src/pages/Auth/AccountDeleted/DoNotCallback';
import NotFound from 'src/pages/NotFound';
import Login from 'src/pages/Auth/Login';

// routes
const routes = [
  { path: '/auth/login', component: Login, exact: true },
  // { path: '/account-deleted', component: AccountDeleted, exact: true, layout: AuthLayout },
  // { path: '/account-deleted/call-back', component: AccountDeletedCallBack, exact: true },
  // {
  //   path: '/account-deleted/do-not-call-back',
  //   component: AccountDeletedDoNotCallBack,
  //   exact: true
  // },
  {
    path: '/learning-dashboard',
    component: Dashboard,
    exact: true,
    topTitle: 'Dashboard',
    layoutClassName: 'dashboard_dashboard_layout'
  },
  {
    path: '/learning/course/:courseCode/dashboard',
    component: TrainingCourseDashboard,
    exact: true
  },
  {
    path: '/learning/course/:courseCode/terms-to-know',
    component: TrainingCourseTermsToKnow,
    exact: true
  },
  {
    path: '/learning/course/:courseCode/lesson/:lessonId',
    component: TrainingCourseLesson,
    exact: true
  },
  {
    path: '/learning/course/:courseCode/lesson/:lessonId/check-in',
    component: TrainingCourseLessonCheckIn,
    exact: true
  },
  {
    path: '/learning/course/:courseCode/quiz-start',
    component: TrainingQuizStart,
    exact: true
  },
  {
    path: '/learning/course/:courseCode/quiz-question',
    component: TrainingQuizQuestion,
    exact: true
  },
  {
    path: '/learning/course/:courseCode/quiz-result',
    component: TrainingQuizReview,
    exact: true
  },
  { path: '/', component: Dashboard, exact: true },
  { component: NotFound }
];

export default routes;
