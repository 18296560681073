import React from 'react';
import TickBlueIcon from 'src/assets/icons/tick-green.svg';
import { markDownFormat } from 'src/utils/formatstring';

const VerticalListGroupItem = ({ item }) => {
  return (
    <>
      {item.body && item.body != '' && (
        <div className="training_course_lesson_tick_blue">
          <img src={TickBlueIcon} alt="" />
          <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.body) }} />
        </div>
      )}
    </>
  );
};

export default VerticalListGroupItem;
