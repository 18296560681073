import React from 'react';

const CategoryItem = ({ name, icon, active }) => {
  return (
    <div className={`training_layout_sidebar_category ${active ? 'active' : ''}`}>
      {icon && icon}
      <span className="training_layout_sidebar_category_name">{name}</span>
      <svg
        id="Group_5391"
        className="training_layout_sidebar_category_right_icon"
        data-name="Group 5391"
        xmlns="http://www.w3.org/2000/svg"
        width="16.367"
        height="13.075"
        viewBox="0 0 16.367 13.075">
        <path
          id="angle-down"
          d="M13.075,1.087a.414.414,0,0,1-.131.3L6.839,7.494a.412.412,0,0,1-.6,0L.131,1.389a.412.412,0,0,1,0-.6L.786.131a.412.412,0,0,1,.6,0L6.537,5.28,11.686.131a.412.412,0,0,1,.6,0l.655.655A.414.414,0,0,1,13.075,1.087Z"
          transform="translate(0 13.075) rotate(-90)"
        />
        <path
          id="angle-down-2"
          data-name="angle-down"
          d="M13.075,1.087a.414.414,0,0,1-.131.3L6.839,7.494a.412.412,0,0,1-.6,0L.131,1.389a.412.412,0,0,1,0-.6L.786.131a.412.412,0,0,1,.6,0L6.537,5.28,11.686.131a.412.412,0,0,1,.6,0l.655.655A.414.414,0,0,1,13.075,1.087Z"
          transform="translate(8.743 13.075) rotate(-90)"
        />
      </svg>
    </div>
  );
};

export default CategoryItem;
