import React from 'react';
import LogoutModal from 'src/components/Dashboard/LogoutModal';
import { useAppSelector } from 'src/hooks';
import { logoutModalVisible } from 'src/store/setting/settingSlice';

const ModalProvider = ({ children }) => {
  const modalVisible = useAppSelector(logoutModalVisible);
  return (
    <>
      {children}
      <LogoutModal visible={modalVisible} />
    </>
  );
};

export default ModalProvider;
