import React from 'react';
import { Link } from 'react-router-dom';
import LessonCompleteIcon from 'src/assets/images/training/lesson-complete-icon.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import { useAppSelector } from 'src/hooks';
import { getNextLesson } from 'src/store/training/trainingSlice';

const LessonCompleteModal = ({ visible }) => {
  const course = useAppSelector((state) => state.training.currentCourse);
  const lesson = useAppSelector((state) => state.training.currentLesson);
  const nextLesson = useAppSelector(getNextLesson(lesson.lessonId));

  return (
    <div
      className="training_course_lesson_check_in_complete_modal"
      style={{ display: visible ? 'block' : 'none' }}>
      <div className="training_course_lesson_check_in_complete_modal_backdrop" />
      <div className="training_course_lesson_check_in_complete_modal_body">
        <div className="training_course_lesson_check_in_complete_modal_content">
          <img
            src={LessonCompleteIcon}
            alt="Icon"
            className="training_course_lesson_check_in_complete_modal_icon"
          />
          <div className="training_course_lesson_check_in_complete_modal_title">
            Lesson complete!
          </div>
          <div className="training_course_lesson_check_in_complete_modal_description">
            You have completed Lesson {lesson.lessonNumber}
            {nextLesson && (
              <>
                <br />{' '}
                <strong>
                  Next up: Lesson {nextLesson.lessonNumber} - {nextLesson.title}
                </strong>
              </>
            )}
          </div>
          <div className="training_course_lesson_check_in_complete_modal_actions">
            <Link
              to={`/`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton text="Back to course overview" />
            </Link>
            {nextLesson ? (
              <Link
                to={`/learning/course/${course.courseCode}/lesson/${nextLesson.lessonId}`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <SecondaryButton text={`Go to lesson ${nextLesson.lessonNumber}`} />
              </Link>
            ) : (
              <Link
                to={`/learning/course/${course.courseCode}/quiz-start`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <SecondaryButton text={`Go to Final Quiz`} />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonCompleteModal;
