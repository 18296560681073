import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowIcon from 'src/assets/images/training/arrow-icon.svg';
import CloseIconRed from 'src/assets/images/training/close-icon-red.svg';
import ExitLessonModal from 'src/components/Training/Course/ExitLessonModal';

const BreadCrumb = ({ items }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="training_layout_breadcrumb">
      <div className="training_layout_breadcrumb_list grid">
        {items?.length &&
          items.map((item, index) => (
            <Link
              to={item.path ? item.path : '#'}
              key={index}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <div
                className={`training_layout_breadcrumb_item ${
                  index === items.length - 1 ? 'active' : ''
                }`}>
                <span className="training_layout_breadcrumb_label">{item.title}</span>
                {index < items.length - 1 && (
                  <img
                    src={ArrowIcon}
                    alt="Arrow"
                    className="training_layout_breadcrumb_arrow_icon"
                  />
                )}
              </div>
            </Link>
          ))}
      </div>
      <button className="training_layout_breadcrumb_close_btn" onClick={() => setVisible(true)}>
        <span>Exit course</span>
        <img src={CloseIconRed} alt="" className="training_layout_breadcrumb_close_icon" />
      </button>
      <ExitLessonModal onCloseModal={() => setVisible(false)} visible={visible} />
    </div>
  );
};

export default BreadCrumb;
