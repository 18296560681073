import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ArrowBlueIcon from 'src/assets/images/training/arrow-white-icon.svg';
import LockIcon from 'src/assets/images/training/lock-icon.svg';
import QuizIcon from 'src/assets/images/training/quiz-icon.svg';
import CourseIconCompleted from '../../../assets/images/dashboard/course-icon-5.svg';
import { useAppSelector } from 'src/hooks';

const MenuItem = ({ id, title, name, type = 'locked', quiz }) => {
  const course = useAppSelector((state) => state.training.currentCourse);
  const icon = {
    ongoing: ArrowBlueIcon,
    locked: LockIcon,
    quiz: QuizIcon
  };
  const location = useLocation();
  const link = quiz
    ? `/learning/course/${course.courseCode}/quiz-start`
    : `/learning/course/${course.courseCode}/lesson/${id}`;

  let active = link == location.pathname;

  if (quiz && location.pathname == `/learning/course/${course.courseCode}/quiz-question`) {
    active = true;
  }

  return (
    <div className={`training_layout_sidebar_lesson type_${type} ${active ? 'active' : ''}`}>
      <Link
        to={link}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}>
        <div className="training_layout_sidebar_lesson_wrap">
          <div className="training_layout_sidebar_lesson_body">
            <div className="training_layout_sidebar_lesson_title">{title}</div>
            <span className="training_layout_sidebar_lesson_name">{name}</span>
          </div>
          <img
            src={!active && !quiz ? CourseIconCompleted : icon[type]}
            alt="Icon"
            className="training_layout_sidebar_lesson_icon"
          />
        </div>
      </Link>
    </div>
  );
};

export default MenuItem;
