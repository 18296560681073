import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CircleRedIcon from 'src/assets/images/training/close-circle-black.svg';
import ExitIcon from 'src/assets/images/training/logout-icon.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import { useAppDispatch } from 'src/hooks';
import { logout } from 'src/store/auth/authSlice';
import { hideLogoutModal } from 'src/store/setting/settingSlice';

const LogoutModal = ({ visible }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (visible) {
      document.getElementById('root').classList.add('position-fixed');
    } else {
      document.getElementById('root').classList.remove('position-fixed');
    }
  }, [visible]);

  const onCloseModal = () => {
    dispatch(hideLogoutModal());
  };

  return (
    <div className="dashboard_logout_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="dashboard_logout_modal_backdrop" onClick={() => onCloseModal()} />
      <div className="dashboard_logout_modal_body">
        <div className="dashboard_logout_modal_content">
          <button className="dashboard_logout_modal_close_btn" onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img src={ExitIcon} alt="Icon" className="dashboard_logout_modal_icon" />
          <div className="dashboard_logout_modal_title">Are you sure you want to log out?</div>
          <div className="dashboard_logout_modal_actions">
            <PrimaryButton
              text="Yes, log me out"
              className="dashboard_logout_modal_back"
              onClick={() => {
                dispatch(hideLogoutModal());
                dispatch(logout({ history }));
              }}
            />
            <SecondaryButton
              text="Cancel"
              className="dashboard_logout_modal_next"
              onClick={() => onCloseModal()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
