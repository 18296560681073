import { createSelector, createSlice } from '@reduxjs/toolkit';

const user = localStorage.getItem('user') || null;
const agent = localStorage.getItem('agent') || null;
const initialState = {
  loading: 'idle',
  user: user ? JSON.parse(user) : null,
  agent: agent ? JSON.parse(agent) : null,
  error: null,
  token: localStorage.getItem('token') || null,
  userId: localStorage.getItem('userId') || null
  // token: localStorage.getItem('token')
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      state.agent = null;
      state.user = null;
      localStorage.clear();
      action.payload.history.push('/auth/login/cellphone');
    },
    login: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.token = action.payload.token;
      localStorage.setItem('userId', action.payload.userId);
      state.userId = action.payload.userId;
    }
  },
  extraReducers: {}
});

//actions
export const { logout, login } = authSlice.actions;

// export const logoutModalVisible = (state) => state.setting.logoutModalVisible;

export const selectors = {
  getUser: createSelector(
    (state) => state.user,
    (user) => user
  )
};

export default authSlice.reducer;
