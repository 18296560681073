import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import {
  fetchCourseFull,
  fetchCourses,
  getCurrentQuizQuestion
} from 'src/store/training/trainingSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import TrainingLayout from 'src/layout/Training';
import Content from 'src/components/Training/Course/QuizQuestion/Content';
import useQuery from 'src/hooks/useQuery';

const TrainingQuiz = () => {
  const params = useParams();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const courses = useAppSelector((state) => state.training.courses);
  const course = useAppSelector((state) => state.training.currentCourse);
  const error = useAppSelector((state) => state.training.error);
  const currentQuestion = useAppSelector(getCurrentQuizQuestion(query.get('questionId')));

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    if (courses.length) {
      dispatch(fetchCourseFull(params.courseCode));
    }
  }, [courses]);

  if (error) {
    return <Redirect to={'/learning-dashboard'} />;
  }

  if (course && currentQuestion) {
    return (
      <TrainingLayout
        title={course.name}
        breadcrumb={[
          { title: 'Learning courses', path: '/learning-dashboard' },
          {
            title: course.name,
            path: `/learning/course/${course.courseCode}/dashboard`
          },
          { title: `Quiz: Question ${currentQuestion.questionNumber}`, path: '#' }
        ]}>
        <Content question={currentQuestion} />
      </TrainingLayout>
    );
  } else {
    return null;
  }
};

export default TrainingQuiz;
