import React, { useId, useState } from 'react';
import { baseURL } from 'src/utils/api';
import { markDownFormat } from 'src/utils/formatstring';
import InfoIcon from 'src/assets/images/training/round-info-blue-icon.svg';
// import TickBlueIcon from 'src/assets/icons/tick-green.svg';

const CardListGroupItem = ({ item }) => {
  const id = useId();
  const [desktopWidth, setDesktopWidth] = useState(0);
  const [mobileWidth, setMobileWidth] = useState(0);
  const css = `
    @media only screen and (max-width: 480px) {
      img[nonce='${id}mobile'] {
        width: ${(mobileWidth * 100) / 360}vw !important;
        max-width: 100% !important;
      }
    }
    @media only screen and (min-width: 481px) and (max-width: 840px) {
      img[nonce='${id}desktop'] {
        width: ${(desktopWidth * 100) / 1440}vw !important;
        max-width: 100% !important;
      }
    }
    @media only screen and (min-width: 841px) and (max-width: 1440px) {
      img[nonce='${id}desktop'] {
        width: ${(desktopWidth * 100) / 1440}vw !important;
        max-width: 100% !important;
      }
    }
    @media only screen and (min-width: 1441px) {
      img[nonce='${id}desktop'] {
        width: ${desktopWidth}px !important;
        max-width: 100% !important;
      }
    }
  `;
  // <div className="training_course_lesson_card_list_item">
  //   <img
  //     src={TickBlueIcon}
  //     alt="Title Icon"
  //     className="training_course_lesson_card_list_item_icon"
  //   />
  //   <div className="training_course_lesson_card_list_item_title">Airtime</div>
  //   <div className="training_course_lesson_card_list_item_body">
  //     You will be able to offer airtime and SIM starter packs for the major cellular networks.
  //     Airtime is bought by your customers to top up their balances on their mobile phones
  //   </div>
  // </div>

  if (item.titleIcon) {
    return (
      <div className="training_course_lesson_card_list_item">
        {item.titleIcon && item.titleIcon != '' && (
          <img
            src={`${baseURL}image/${item.titleIcon}`}
            alt="Title Icon"
            className="training_course_lesson_card_list_item_icon"
          />
        )}
        <div>
          <div className="training_course_lesson_card_list_item_title">
            <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.title) }} />
          </div>
          {item.tipContent && item.tipContent != '' && (
            <div className="training_course_lesson_message">
              <img src={InfoIcon} alt="Icon" className="training_course_lesson_message_icon" />
              <pre className="training_course_lesson_message_text">
                <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.tipContent) }} />
              </pre>
            </div>
          )}
          <div className="training_course_lesson_card_list_item_body">
            <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.body) }} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <style scoped>{css}</style>
        <div
          className="training_course_lesson_card_list_item"
          placement={item.desktopImagePlacement?.toLowerCase()}
          mobile-placement={item.mobileImagePlacement?.toLowerCase()}>
          {(item.desktopImage || item.mobileImage) && (
            <div className="training_course_lesson_card_list_item_image">
              {item.desktopImage && (
                <img
                  src={`${baseURL}image/${item.desktopImage}`}
                  alt="Title Icon"
                  nonce={id + 'desktop'}
                  className="training_course_lesson_card_list_item_image_desktop"
                  onLoad={(e) => {
                    setDesktopWidth(e.target.naturalWidth);
                  }}
                />
              )}
              {item.mobileImage && (
                <img
                  src={`${baseURL}image/${item.mobileImage}`}
                  alt="Title Icon"
                  nonce={id + 'mobile'}
                  className="training_course_lesson_card_list_item_image_mobile"
                  onLoad={(e) => {
                    setMobileWidth(e.target.naturalWidth);
                  }}
                />
              )}
            </div>
          )}
          <div className="training_course_lesson_card_list_item_content">
            <div className="training_course_lesson_card_list_item_title">
              <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.title) }} />
            </div>
            {item.tipContent && item.tipContent != '' && (
              <div className="training_course_lesson_message">
                <img src={InfoIcon} alt="Icon" className="training_course_lesson_message_icon" />
                <pre className="training_course_lesson_message_text">
                  <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.tipContent) }} />
                </pre>
              </div>
            )}
            <div className="training_course_lesson_card_list_item_body">
              <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.body) }} />
            </div>
            {/* <div className="training_course_lesson_card_list_item_body">
            You will be able to offer data bundles for the major cellular networks. Data is used by
            your customers to browse the internet or to use mobile apps like Facebook and Whatsapp.
          </div> */}
          </div>
        </div>
      </>
    );
  }
};

export default CardListGroupItem;
