import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { fetchCourseFull, fetchCourses, setLesson } from 'src/store/training/trainingSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import TrainingLayout from 'src/layout/Training';
import Content from 'src/components/Training/Course/LessonCheckIn/Content';

const TrainingLesson = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const courses = useAppSelector((state) => state.training.courses);
  const course = useAppSelector((state) => state.training.currentCourse);
  const lesson = useAppSelector((state) => state.training.currentLesson);
  const error = useAppSelector((state) => state.training.error);

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    if (courses.length) {
      dispatch(fetchCourseFull(params.courseCode));
    }
  }, [courses]);

  useEffect(() => {
    if (course) {
      dispatch(setLesson({ lessonId: params.lessonId }));
    }
  }, [course]);

  if (error) {
    return <Redirect to={'/learning-dashboard'} />;
  }

  if (course && lesson) {
    return (
      <TrainingLayout
        title={course.name}
        breadcrumb={[
          { title: 'Learning courses', path: '/learning-dashboard' },
          {
            title: course.name,
            path: `/learning/course/${course.courseCode}/dashboard`
          },
          { title: `Lesson ${lesson.lessonNumber}: Lesson check-in`, path: '#' }
        ]}>
        <Content lesson={lesson} />
      </TrainingLayout>
    );
  } else {
    return null;
  }
};

export default TrainingLesson;
