import React, { useState, useEffect } from 'react';
import MenuIcon from 'src/assets/images/training/menu-icon.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import SelfieImg from 'src/assets/images/selfie.png';
import CloseIconRed from 'src/assets/images/training/close-icon-red.svg';
import ExitLessonModal from 'src/components/Training/Course/ExitLessonModal';
import SideBar from './SideBar';
import Footer from './Footer';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { hideMenu, menuVisible, showMenu, showLogoutModal } from 'src/store/setting/settingSlice';

const Header = ({ title }) => {
  const dispatch = useAppDispatch();
  const isOpenMenu = useAppSelector(menuVisible);
  const [exitModalVisible, setExitModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(hideMenu());
  }, []);

  return (
    <>
      <div className="training_layout_header">
        <div className="training_layout_header_left">
          <button className="training_layout_header_menu_btn" onClick={() => dispatch(showMenu())}>
            <img src={MenuIcon} alt="" className="training_layout_header_menu_icon" />
          </button>
        </div>
        <div className="training_layout_header_title">{title}</div>
        <div className="training_layout_header_right">
          <button
            className="training_layout_header_close_btn"
            onClick={() => setExitModalVisible(true)}>
            <img src={CloseIconRed} alt="" className="training_layout_header_close_icon" />
          </button>
          <div className="training_layout_header_profile_container">
            <button
              className="training_layout_header_profile_logout_btn"
              hidden={!visible}
              onClick={() => dispatch(showLogoutModal())}>
              <span>Logout</span>
              <LogoutIcon />
            </button>
            <img
              src={SelfieImg}
              alt="avatar"
              className="training_layout_header_profile_avatar"
              onClick={() => setVisible(!visible)}
            />
          </div>
        </div>
      </div>
      <div className={`training_layout_header_sidebar_wrapper ${isOpenMenu ? 'show' : 'hide'}`}>
        <SideBar menu />
        <Footer />
      </div>
      <ExitLessonModal onCloseModal={() => setExitModalVisible(false)} visible={exitModalVisible} />
    </>
  );
};

export default Header;
