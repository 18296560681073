import React from 'react';
import CloseIconWhite from 'src/assets/images/training/close-icon-white.svg';
import SideBarProgressBar from 'src/components/Training/Sidebar/SideBarProgressBar';
import CategoryItem from 'src/components/Training/Sidebar/CategoryItem';
import MenuItem from 'src/components/Training/Sidebar/MenuItem';
import { PrimaryButton } from 'src/components/Common/Button';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { hideMenu } from 'src/store/setting/settingSlice';
import { baseURL } from 'src/utils/api';
import { Link, useLocation } from 'react-router-dom';
import { getQuizDetail } from 'src/store/training/trainingSlice';

const SideBar = ({ menu }) => {
  const dispatch = useAppDispatch();
  const course = useAppSelector((state) => state.training.currentCourse);
  const quiz = useAppSelector(getQuizDetail);
  const location = useLocation();

  if (course) {
    return (
      <div className="training_layout_sidebar">
        <div className="training_layout_sidebar_content">
          <div className="training_layout_sidebar_content_header">
            <div className="training_layout_sidebar_overview">
              <div
                className={`training_layout_sidebar_overview_icon${menu ? ' icon_menu' : ''}`}
                style={{ backgroundColor: course.defaultBackgroundColour }}>
                <img src={`${baseURL}image/${course.defaultIcon}`} alt="Icon" />
              </div>
              <span className="training_layout_sidebar_overview_left_lessons">
                {course.lessons.length} lessons
              </span>
              <span className="training_layout_sidebar_overview_divider" />
              <span className="training_layout_sidebar_overview_points">
                {course.points} points
              </span>
            </div>
            <div className="training_layout_sidebar_title">{course.name}</div>
            <SideBarProgressBar total={4} done={0} />
            {menu && (
              <button
                className="training_layout_sidebar_close_btn"
                onClick={() => dispatch(hideMenu())}>
                <img src={CloseIconWhite} alt="" className="training_layout_sidebar_close_icon" />
              </button>
            )}
          </div>
          <div className="training_layout_sidebar_content_body">
            <div className="training_layout_sidebar_categories">
              <Link
                to={`/learning-dashboard`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <CategoryItem
                  icon={
                    <svg
                      className="training_layout_sidebar_category_left_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.52"
                      height="17.52"
                      viewBox="0 0 17.52 17.52">
                      <path
                        id="Path_5895"
                        data-name="Path 5895"
                        d="M84.76,427a8.76,8.76,0,1,0,8.76,8.76A8.77,8.77,0,0,0,84.76,427Zm1.94,13.347c-.483.19-2.155.988-3.119.139a1.223,1.223,0,0,1-.431-.962,11.664,11.664,0,0,1,.671-2.889,4.16,4.16,0,0,0,.169-.958c0-.512-.194-.648-.721-.648a2.416,2.416,0,0,0-.8.188l.142-.583a5.576,5.576,0,0,1,1.913-.518c.926,0,1.608.462,1.608,1.341a4.078,4.078,0,0,1-.136,1l-.533,1.885c-.11.381-.31,1.221,0,1.47a1.617,1.617,0,0,0,1.378-.052Zm-.88-7.507a1.1,1.1,0,1,1,1.095-1.095A1.1,1.1,0,0,1,85.82,432.84Z"
                        transform="translate(-76 -427)"
                      />
                    </svg>
                  }
                  name="All learning courses"
                />
              </Link>
              <Link
                to={`/learning/course/${course.courseCode}/terms-to-know`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <CategoryItem
                  icon={
                    <svg
                      className="training_layout_sidebar_category_left_icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 21C4 20.448 4.448 20 5 20H20C21.105 20 22 19.105 22 18V3C22 1.343 20.657 0 19 0H12V8L9 6L6 8V0H5C3.343 0 2 1.343 2 3V21C2 22.657 3.343 24 5 24H21C21.552 24 22 23.552 22 23C22 22.448 21.552 22 21 22H5C4.448 22 4 21.552 4 21Z" />
                    </svg>
                  }
                  name="Terms to Know"
                  active={
                    location.pathname == `/learning/course/${course.courseCode}/terms-to-know`
                  }
                />
              </Link>
            </div>
            <div className="training_layout_sidebar_lessons">
              {course.lessons.map((lesson, index) => (
                <MenuItem
                  id={lesson.lessonId}
                  title={`Up Next: LESSON ${lesson.lessonNumber}`}
                  name={lesson.title}
                  type={'ongoing'}
                  key={index}
                />
              ))}
              {quiz && (
                <MenuItem
                  title={`${course.points} POINTS`}
                  name={quiz.name}
                  type={'ongoing'}
                  quiz
                />
              )}
            </div>
            <Link
              to={'/learning-dashboard'}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton text="All learning courses" />
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SideBar;
